import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFolder } from 'react-icons/bs';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Modal,
	Page,
	Table,
	Select,
	Time,
} from '../../components';

ChartJS.register(ArcElement);

const Surveys = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [showResult, setShowResult] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveSurvey,
		watch: watchSaveSurvey,
		setValue: setValueSaveSurvey,
		handleSubmit: handleSubmitSaveSurvey,
		reset: resetSaveSurvey,
		formState: { errors: errorsSaveSurvey },
	} = useForm();

	const api = {
		getSurveys: useAxios(),
		saveSurvey: useAxios(),
		removeSurvey: useAxios(),
		getSurvey: useAxios(),
	};

	const getSurveys = () => {
		api.getSurveys.fetch({
			method: 'GET',
			url: '/survey/admin/get/all/surveys',
		});
	};

	const saveSurvey = (data) => {
		const choices = [];

		// console.log('data', data);

		if (data.choices) {
			data.choices.slice(0, data.optionCount).forEach((choice) => {
				if (choice) {
					if (data.surveyId) {
						const survey =
							api.getSurveys.response.data.content[
								api.getSurveys.response.data.content.findIndex(
									(object) =>
										object.id ===
										parseInt(data.surveyId, 10)
								)
							];

						const option =
							survey.surveyChoiceDTOList[
								survey.surveyChoiceDTOList.findIndex(
									(object) => object.name === choice
								)
							];

						choices.push({
							...(option ? { id: option.id } : {}),
							name: choice,
						});
					} else {
						choices.push({
							name: choice,
						});
					}
				}
				return false;
			});
		}

		const body = {
			...data,
			active: data.active !== 'false',
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
			surveyChoiceDTOList: choices,
		};

		delete body.optionCount;
		delete body.choices;

		api.saveSurvey.fetch({
			method: 'POST',
			url: '/survey/create/survey',
			data: body,
		});
	};

	const removeSurvey = (id) => {
		api.removeSurvey.fetch({
			method: 'POST',
			url: `/survey/delete/survey/${id}`,
		});
	};

	const getSurvey = (data) => {
		api.getSurvey.fetch({
			method: 'POST',
			url: '/survey/get/survey/results',
			data,
		});
	};

	React.useEffect(() => {
		getSurveys();
	}, []);

	React.useEffect(() => {
		if (api.getSurveys.response) {
			setDashboardItems([
				{
					id: '1',
					name: 'Toplam',
					value: 0,
					icon: <BsFolder className="h-8 w-8 opacity-25" />,
				},
				{
					id: '3',
					name: 'Aktif',
					value: 0,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Aktif', 'Pasif'],
								datasets: [
									{
										data: [19, 12],
										backgroundColor: ['#a7f3d0', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
				{
					id: '4',
					name: 'Pasif',
					value: 0,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Pasif', 'Aktif'],
								datasets: [
									{
										data: [12, 19],
										backgroundColor: ['#fecdd3', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
			]);
		}
	}, [api.getSurveys.response]);

	React.useEffect(() => {
		if (api.getSurveys.error) {
			toast.error(api.getSurveys.error.message);
		}
	}, [api.getSurveys.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit && !showEdit.id) {
			setValueSaveSurvey('optionCount', 4);
		}

		if (showEdit.id && api.getSurveys.response) {
			const { id } = showEdit;
			const item =
				api.getSurveys.response.data.content[
					api.getSurveys.response.data.content.findIndex(
						(object) => object.id === id
					)
				];

			const values = [
				{ title: item.title },
				{ description: item.description },
				{ score: item.score },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },

				{ optionCount: item.surveyChoiceDTOList.length },
			];

			values.forEach((value) => {
				setValueSaveSurvey(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
			item.surveyChoiceDTOList.forEach((choice, index) => {
				setValueSaveSurvey(`choices[${index}]`, choice.name);
			});
		}
	}, [showEdit, api.getSurveys.response]);

	React.useEffect(() => {
		if (api.saveSurvey.response) {
			resetSaveSurvey();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getSurveys();
		}
	}, [api.saveSurvey.response]);

	React.useEffect(() => {
		if (api.saveSurvey.error) {
			toast.error(api.saveSurvey.error.message);
		}
	}, [api.saveSurvey.error]);

	React.useEffect(() => {
		if (api.removeSurvey.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getSurveys();
		}
	}, [api.removeSurvey.response]);

	React.useEffect(() => {
		if (api.removeSurvey.error) {
			toast.error(api.removeSurvey.error.message);
		}
	}, [api.removeSurvey.error]);

	return (
		<Page>
			<Page.Header header="Portal" title="Tüm Anketler">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getSurveys.loading}
				show={
					api.getSurveys.response &&
					api.getSurveys.response?.data?.content &&
					api.getSurveys.loading === false
				}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Anketler',
								'Puan',
								'Tarih',
								'Durum',
								'Katılımcı',
								'',
							]}
						/>
						<Table.Body>
							{api.getSurveys.response?.data?.content?.length &&
								api.getSurveys.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<div className="grid gap-1">
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.title}
													</h6>
													<p className="truncate whitespace-nowrap text-xs text-slate-400">
														{item.description}
													</p>
												</div>
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{item.score}
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.timeEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												{item.active ? (
													<Badge variant="success">
														<div className="aspect-square w-1.5 animate-pulse rounded-full bg-green-500" />
														Aktif
													</Badge>
												) : (
													<Badge variant="danger">
														<div className="aspect-square w-1.5 rounded-full bg-red-500" />
														Pasif
													</Badge>
												)}
											</Table.Column>
											<Table.Column>
												{item.totalParticipant}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2">
													{item.totalParticipant >
														0 && (
														<Button
															size="xs"
															variant="primary-outline"
															className="inline-flex"
															onClick={() => {
																getSurvey({
																	surveyId:
																		item.id,
																});
																setShowResult({
																	id: item.id,
																});
															}}
														>
															Sonuçlar
														</Button>
													)}
													<Button
														size="xs"
														variant="secondary-outline"
														className="inline-flex"
														onClick={() => {
															setShowEdit({
																id: item.id,
															});
														}}
													>
														Düzenle
													</Button>
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getSurveys.loading ||
					api.saveSurvey.loading ||
					api.removeSurvey.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveSurvey();
				}}
			>
				<form onSubmit={handleSubmitSaveSurvey(saveSurvey)} noValidate>
					<div className="px-6">
						<Modal.Section title="Ayarlar">
							<Input
								type="text"
								name="title"
								label="Başlık"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveSurvey}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveSurvey}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateBegin"
								label="Başlangıç Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now()).format(
									'YYYY-MM-DD HH:mm'
								)}
								register={registerSaveSurvey}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateEnd"
								label="Bitiş Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now())
									.add(1, 'year')
									.format('YYYY-MM-DD HH:mm')}
								register={registerSaveSurvey}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="number"
								name="score"
								label="Puan"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveSurvey}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="active"
								label="Durum"
								grid
								options={[
									{
										name: 'Aktif',
										value: true,
									},
									{
										name: 'Pasif',
										value: false,
									},
								]}
								defaultValue
								register={registerSaveSurvey}
								// validation={{
								// 	required: 'Bu alan zorunludur.',
								// }}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>
						<Modal.Section title="Seçenekler">
							<Select
								name="optionCount"
								label="Seçenek Adeti"
								grid
								options={[
									{
										name: '2',
										value: 2,
									},
									{
										name: '3',
										value: 3,
									},
									{
										name: '4',
										value: 4,
									},
									{
										name: '5',
										value: 5,
									},
									{
										name: '6',
										value: 6,
									},
									{
										name: '7',
										value: 7,
									},
									{
										name: '8',
										value: 8,
									},
									{
										name: '9',
										value: 9,
									},
									{
										name: '10',
										value: 10,
									},
								]}
								register={registerSaveSurvey}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveSurvey}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							{[
								...Array(
									watchSaveSurvey('optionCount')
										? Number(watchSaveSurvey('optionCount'))
										: 0
								),
							].map((option, index) => (
								<Input
									key={index}
									type="text"
									name={`choices[${index}]`}
									label={`Seçenek ${index + 1}`}
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveSurvey}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveSurvey}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
							))}
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="surveyId"
							register={registerSaveSurvey}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => removeSurvey(showEdit.id)}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveSurvey).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			<Modal
				show={!!showResult.id}
				loading={api.getSurveys.loading || api.getSurvey.loading}
				size="lg"
				title="Sonuçlar"
				onClose={() => {
					setShowResult(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					{/* const item =
					api.getSurveys.response.data.content[
						api.getSurveys.response.data.content.findIndex(
							(object) => object.id === id
						)
					]; */}
					{api.getSurvey.response?.data?.surveyChoiceDTOList && (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										position: 'right',
										align: 'left',
										labels: {
											font: {
												family: '"Lexend Deca", sans-serif',
												weight: '300',
												size: 12,
											},
											color: '#94A3B8',
										},
									},
								},
							}}
							data={{
								labels: [
									...api.getSurvey.response.data.surveyChoiceDTOList.map(
										(item) => item.name
									),
								],
								datasets: [
									{
										data: [
											...api.getSurvey.response.data.surveyChoiceDTOList.map(
												(item) => item.choicePercent
											),
										],
										backgroundColor: [
											'#0074D9',
											'#FF4136',
											'#2ECC40',
											'#FF851B',
											'#7FDBFF',
											'#B10DC9',
											'#FFDC00',
											'#001f3f',
											'#39CCCC',
											'#01FF70',
											'#85144b',
											'#F012BE',
											'#3D9970',
											'#111111',
											'#AAAAAA',
										],
										borderWidth: 0,
									},
								],
							}}
						/>
					)}
				</div>
			</Modal>
		</Page>
	);
};

export default Surveys;
