import React from 'react';
import { HiCog } from 'react-icons/hi';

import Context from '../../Context';
import Search from './Search';
import Filter from './Filter';
import Add from './Add';

const Header = ({ children, header, title }) => {
	const [context, setContext] = React.useContext(Context);
	const { state } = context;

	return (
		<div className="grid grid-cols-2 items-center gap-3 md:flex">
			<h3 className="text-2xl font-medium text-slate-700  md:mr-auto">
				{header && (
					<span className="mb-1 block text-sm font-normal text-slate-400">
						{header}
					</span>
				)}
				{typeof state.showSettings !== 'undefined' ? (
					<button
						type="button"
						className="group flex items-center gap-2"
						onClick={() => {
							setContext({
								...context,
								state: {
									...state,
									showSettings: true,
								},
							});
						}}
					>
						{title}
						<HiCog className="h-6 w-6 opacity-25 transition duration-300 group-hover:opacity-100" />
					</button>
				) : (
					<span className="group flex items-center gap-2">
						{title}
					</span>
				)}
			</h3>

			{children}
		</div>
	);
};

Header.Search = Search;
Header.Filter = Filter;
Header.Add = Add;

export default Header;
