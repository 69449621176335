import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';

import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronForward } from 'react-icons/io5';
import {
	BsFillPlayCircleFill,
	BsCheckCircleFill,
	BsFillXCircleFill,
} from 'react-icons/bs';
import { useAxios } from '../../hooks';

import {
	Button,
	Input,
	Modal,
	Page,
	Select,
	Table,
	Time,
	Item,
	Badge,
	TextArea,
} from '../../components';

const AcademyTopics = () => {
	const [showEditTopic, setShowEditTopic] = React.useState(false);
	const [showEditVideo, setShowEditVideo] = React.useState(false);
	const [showVideos, setShowVideos] = React.useState(false);
	const [videoFile, setVideoFile] = React.useState();
	const [videoContent, setVideoContent] = React.useState();
	const [params, setParams] = useSearchParams();
	const [currentImage, setCurrentImage] = React.useState([]);

	const [questions, setQuestions] = React.useState([]);
	const [surveys, setSurveys] = React.useState([]);

	/* form */

	const {
		register: registerSaveAcademyTopic,
		setValue: setValueSaveAcademyTopic,
		watch: watchSaveAcademyTopic,
		handleSubmit: handleSubmitSaveAcademyTopic,
		reset: resetSaveAcademyTopic,
		formState: { errors: errorsSaveAcademyTopic },
	} = useForm();

	const {
		register: registerSaveAcademyVideo,
		setValue: setValueSaveAcademyVideo,
		watch: watchSaveAcademyVideo,
		handleSubmit: handleSubmitSaveAcademyVideo,
		reset: resetSaveAcademyVideo,
		formState: { errors: errorsSaveAcademyVideo },
	} = useForm();

	/* api */

	const api = {
		getAcademyCategories: useAxios(),

		getAcademyTopics: useAxios(),
		saveAcademyTopic: useAxios(),
		removeAcademyTopic: useAxios(),

		getAcademyVideos: useAxios(),
		saveAcademyVideo: useAxios(),
		removeAcademyVideo: useAxios(),
	};

	/* getAcademyCategories */

	const getAcademyCategories = () => {
		api.getAcademyCategories.fetch({
			method: 'GET',
			url: '/academy/admin/get/all/video/categories',
		});
	};

	React.useEffect(() => {
		if (api.getAcademyCategories.error) {
			toast.error(api.getAcademyCategories.error.message);
		}
	}, [api.getAcademyCategories.error]);

	React.useEffect(() => {
		getAcademyCategories();
	}, []);

	/* getAcademyTopics */

	const getAcademyTopics = () => {
		api.getAcademyTopics.fetch({
			method: 'GET',
			url: '/academy/admin/get/all/video/topics?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		if (api.getAcademyTopics.response) {
			console.log('topics', api.getAcademyTopics.response);
		}
	}, [api.getAcademyTopics.response]);

	React.useEffect(() => {
		if (api.getAcademyTopics.error) {
			toast.error(api.getAcademyTopics.error.message);
		}
	}, [api.getAcademyTopics.error]);

	React.useEffect(() => {
		getAcademyTopics();
	}, []);

	/* saveAcademyTopic */

	const saveAcademyTopic = (data) => {
		console.log(data);

		const body = {
			...data,
			...(data.id ? { videoTopicId: parseInt(data.id, 10) } : null),
			...(data.subId
				? { videoSubTopicId: parseInt(data.subId, 10) }
				: null),
			...(data.videoCategoryId
				? { videoCategoryId: parseInt(data.videoCategoryId, 10) }
				: null),
		};

		if (data.type) {
			delete body.type;
		}

		if (data.id) {
			delete body.id;
		}

		if (data.subId) {
			delete body.subId;
		}

		if (data.type === 'subTopic' || data.subId) {
			api.saveAcademyTopic.fetch({
				method: 'POST',
				url: '/academy/admin/save/video/subTopic',
				data: body,
			});
		} else {
			const formData = new FormData();
			formData.append(
				'request',
				JSON.stringify({
					...body,
					...(currentImage.length &&
					currentImage[0] &&
					typeof currentImage[0] === 'string'
						? { contents: [currentImage[0]] }
						: null),
				})
			);
			if (
				currentImage.length &&
				currentImage[0] &&
				currentImage[0] instanceof File
			) {
				formData.append('icon', currentImage[0]);
			}
			api.saveAcademyTopic.fetch({
				method: 'POST',
				url: '/academy/admin/save/video/topic',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
		}
	};

	React.useEffect(() => {
		if (api.saveAcademyTopic.response) {
			resetSaveAcademyTopic();
			setShowEditTopic(false);
			toast.success('Kaydedildi.');
			getAcademyTopics();
		}
	}, [api.saveAcademyTopic.response]);

	React.useEffect(() => {
		if (api.saveAcademyTopic.error) {
			toast.error(api.saveAcademyTopic.error.message);
		}
	}, [api.saveAcademyTopic.error]);

	/* editAcademyTopic */

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEditTopic(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEditTopic === true) {
			setValueSaveAcademyTopic('type', 'topic', {
				shouldValidate: true,
			});
		}

		if (
			showEditTopic.id &&
			!showEditTopic.subId &&
			api.getAcademyTopics.response
		) {
			const { id } = showEditTopic;
			const item =
				api.getAcademyTopics.response.data[
					api.getAcademyTopics.response.data.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ name: item.topicName },
				{ description: item.description },
				{ videoCategoryId: item.videoCategoryId },
			];

			values.forEach((value) => {
				setValueSaveAcademyTopic(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (item.url) {
				const images = [];
				images.push(item.url);
				setCurrentImage(images);
			}
		}

		if (showEditTopic.subId && api.getAcademyTopics.response) {
			const { id, subId } = showEditTopic;
			const item =
				api.getAcademyTopics.response.data[
					api.getAcademyTopics.response.data.findIndex(
						(object) => object.id === id
					)
				];
			const subItem =
				item.videoSubTopicDTOList[
					item.videoSubTopicDTOList.findIndex(
						(object) => object.id === subId
					)
				];
			const values = [
				{ name: subItem.name },
				{ subTopicOrder: subItem.subTopicOrder },
				{ videoTopicId: id },
			];

			values.forEach((value) => {
				setValueSaveAcademyTopic(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEditTopic, api.getAcademyTopics.response]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	/* removeAcademyTopic */

	const removeAcademyTopic = (id) => {
		api.removeAcademyTopic.fetch({
			method: 'POST',
			url: showEditTopic.subId
				? '/academy/admin/delete/video/subTopic'
				: '/academy/admin/delete/video/topic',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeAcademyTopic.response) {
			setCurrentImage([]);
			setShowEditTopic(false);
			toast.success('Silindi.');
			getAcademyTopics();
		}
	}, [api.removeAcademyTopic.response]);

	React.useEffect(() => {
		if (api.removeAcademyTopic.error) {
			toast.error(api.removeAcademyTopic.error.message);
		}
	}, [api.removeAcademyTopic.error]);

	/* getAcademyVideos */

	const getAcademyVideos = (data) => {
		api.getAcademyVideos.fetch({
			method: 'GET',
			url: `/academy/admin/get/all/video/details/video/topic/${data.topicId}?page=0&size=9999`,
		});
	};

	React.useEffect(() => {
		if (api.getAcademyVideos.response) {
			console.log('videos', api.getAcademyVideos.response);
		}
	}, [api.getAcademyVideos.response]);

	React.useEffect(() => {
		if (api.getAcademyVideos.error) {
			toast.error(api.getAcademyVideos.error.message);
		}
	}, [api.getAcademyVideos.error]);

	/* saveAcademyVideo */

	const saveAcademyVideo = (data) => {
		const body = {
			...data,
			...(data.id ? { videoId: parseInt(data.id, 10) } : null),
			...(data.minimumRightAnswerCount
				? {
					minimumRightAnswerCount: parseInt(
						data.minimumRightAnswerCount,
						10
					),
				}
				: null),
			...(data.videoOrder
				? { videoOrder: parseInt(data.videoOrder, 10) }
				: null),
			...(data.videoSubTopicId
				? { videoSubTopicId: parseInt(data.videoSubTopicId, 10) }
				: null),
			...(videoContent ? { videoUrl: videoContent } : null),
		};

		if (data.id) {
			delete body.id;
		}

		const formData = new FormData();
		formData.append('saveVideoRequest', JSON.stringify(body));
		if (videoFile) {
			formData.append('videoFile', videoFile);
		}

		console.log(body);

		api.saveAcademyVideo.fetch({
			method: 'POST',
			url: '/academy/admin/save/video',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	React.useEffect(() => {
		if (api.saveAcademyVideo.response) {
			resetSaveAcademyVideo();
			setCurrentImage([]);
			setShowEditTopic(false);
			setShowEditVideo(false);
			toast.success('Kaydedildi.');
			// getAcademyVideos(); // TODO: getAcademyVideos datasini state'e yaz son itemi al
		}
	}, [api.saveAcademyVideo.response]);

	React.useEffect(() => {
		if (api.saveAcademyVideo.error) {
			toast.error(api.saveAcademyVideo.error.message);
		}
	}, [api.saveAcademyVideo.error]);

	/* editAcademyVideo */

	/*

		NEW VIDEO VIDEO WITH EMBED

		{
			+ "title": "İş Ahlakı ve Etik Kurallar",
			+ "description": "İş Ahlakı ve Etik Kurallar açıklama",
			+ "minimumRightAnswerCount": 5,
			+ "videoOrder": 5,
			+ "videoContentType": "EMBEDDED_VIDEO",
			+ "embeddedVideoUrl": "youtube.com/324234",
			+ "videoSubTopicId": 10,
			"createSurveyRequests": [
				{
					"title": "İş Ahlakı ve Etik Kurallar anket1",
					"description": "İş Ahlakı ve Etik Kurallar anket1 açıklama",
					"surveyScore": 100,
					"beginDate": "2023-11-14T15:00:00Z",
					"endDate": "2024-11-15T05:00:00Z",
					"active": true,
					"surveyChoiceDTOList": [
						{
							"name": "İş Ahlakı ve Etik Kurallar anket1 şık 1"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket1 şık 2"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket1 şık 3"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket1 şık 4"
						}
					]
				},
				{
					"title": "İş Ahlakı ve Etik Kurallar anket 2",
					"description": "İş Ahlakı ve Etik Kurallar anket açıklama 2",
					"surveyScore": 100,
					"beginDate": "2023-11-14T15:00:00Z",
					"endDate": "2024-11-15T05:00:00Z",
					"active": true,
					"surveyChoiceDTOList": [
						{
							"name": "İş Ahlakı ve Etik Kurallar anket2 şık 1"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket2 şık 2"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket2 şık 3"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket2 şık 4"
						}
					]
				}
			],
			"saveQuestionRequestList":[
				{
					"title":"soru1 başlık",
					"description":"soru1 açıklama",
					"answerExpiredTime":90,
					"appearTime":40,
					"score":100,
					"choiceDTOList":[
						{
							"description": "soru1 şık1",
							"isTrue": "false"
						},
						{
							"description": "soru2 şık2",
							"isTrue": "false"
						},
						{
							"description": "soru3 şık3",
							"isTrue": "false"
						},
						{
							"description": "soru4 şık4",
							"isTrue": "true"
						}
					]
				}
			]

		}

		EDIT VIDEO WITH EMBED
		(burada id gönderilmeden gönderilenler yeni veri oluşturur, gönderilmeyenler silinir)
		oluşan durumlar;
		1.anket şık 4 gönderilmediği için silindi yerine anket şık 5 yeni kayıt oldu
		2.anket gönderilmediği için silindi 3.anket kayıt oldu
		1.soru id ile gönderildi şıklardan 1ve2 idle gönderildiği için
		düzenlendi diğerleri gönderilmediği için silindi
		anket için geçerli olan şeyler soru için de aynı şekilde
		id gönderilenler düzenlenir gönderilmeyen silinir, yeni gönderilen yeni kayıt olur

		{
			"videoId":1,
			"title": "İş Ahlakı ve Etik Kurallar edit",
			"description": "İş Ahlakı ve Etik Kurallar açıklama edit",
			"minimumRightAnswerCount": 5,
			"videoOrder": 5,
			"videoContentType": "EMBEDDED_VIDEO",
			"embeddedVideoUrl": "youtube.com/324234",
			"videoSubTopicId": 10,
			"createSurveyRequests": [
				{
					"surveyId":1,
					"title": "İş Ahlakı ve Etik Kurallar anket1 edit",
					"description": "İş Ahlakı ve Etik Kurallar anket1 açıklama edit",
					"surveyScore": 100,
					"beginDate": "2023-11-14T15:00:00Z",
					"endDate": "2024-11-15T05:00:00Z",
					"active": true,
					"surveyChoiceDTOList": [
						{
							"id":1,
							"name": "İş Ahlakı ve Etik Kurallar anket şık 1 edit"
						},
						{
							"id:2,
							"name": "İş Ahlakı ve Etik Kurallar anket şık 2 edit"
						},
						{
							"id":3,
							"name": "İş Ahlakı ve Etik Kurallar anket şık 3 edit"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket şık 5 yeni"
						}
					]
				},
				{
					"title": "İş Ahlakı ve Etik Kurallar anket 3 yeni",
					"description": "İş Ahlakı ve Etik Kurallar anket açıklama 3 yeni",
					"surveyScore": 100,
					"beginDate": "2023-11-14T15:00:00Z",
					"endDate": "2024-11-15T05:00:00Z",
					"active": true,
					"surveyChoiceDTOList": [
						{
							"name": "İş Ahlakı ve Etik Kurallar anket3 şık 1 yeni"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket3 şık 2 yeni"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket3 şık 3 yeni"
						},
						{
							"name": "İş Ahlakı ve Etik Kurallar anket3 şık 4 yeni"
						}
					]
				}
			],
			"saveQuestionRequestList":[
				{
					"id:1,
					"title":"soru1 başlık edit",
					"description":"soru1 açıklama edit",
					"answerExpiredTime":90,
					"appearTime":40,
					"score":100,
					"choiceDTOList":[
						{
							"id":1,
							"description": "soru1 şık1 edit",
							"isTrue": "false"
						},
						{
							"id":2,
							"description": "soru2 şık2 edit",
							"isTrue": "false"
						}
					]

				}
			]

		}

		NEW VIDEO VIDEO WITH FILE

		aynı durumlar geçerli sadece

		"videoContentType":"VIDEO" olacak
		"embeddedVideoUrl" gönderilmeyecek

	*/

	React.useEffect(() => {
		if (showEditVideo === true) {
			setValueSaveAcademyVideo('videoContentType', 'EMBEDDED_VIDEO', {
				shouldValidate: true,
			});
		}

		if (showEditVideo.id && api.getAcademyVideos.response) {
			const { id } = showEditVideo;

			const item =
				api.getAcademyVideos.response.data.content[
					api.getAcademyVideos.response.data.content.findIndex(
						(object) => object.videoId === id
					)
				];

			setVideoContent(item.videoUrl);

			const values = [
				{ title: item.title },
				{ description: item.description },
				{ videoOrder: item.videoOrder },
				{ minimumRightAnswerCount: item.minimumRightAnswerCount },
				{ videoContentType: item.videoContentType },
				{ embeddedVideoUrl: item.embeddedVideoUrl },
			];

			// if (item.questionDTOList) {
			// 	let questionDTOList;
			// }

			// questionDTOList
			// surveyDTOList

			values.forEach((value) => {
				setValueSaveAcademyVideo(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEditVideo, api.getAcademyVideos.response]);

	/* removeAcademyVideo */

	const removeAcademyVideo = (id) => {
		api.removeAcademyVideo.fetch({
			method: 'POST',
			url: '/academy/admin/delete/video',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeAcademyVideo.response) {
			console.log(showEditTopic);
			setShowEditVideo(false);
			toast.success('Silindi.');
			// getAcademyVideos(); //TODO: id gonder
		}
	}, [api.removeAcademyVideo.response]);

	React.useEffect(() => {
		if (api.removeAcademyVideo.error) {
			toast.error(api.removeAcademyVideo.error.message);
		}
	}, [api.removeAcademyVideo.error]);

	/* handleVideoEdit */

	const handleEditVideo = (id) => {
		setShowEditVideo({
			id,
		});

		const questionDTOList =
			api.getAcademyVideos.response?.data?.content?.find(
				(object) => object.videoId === id
			)?.questionDTOList;

		if (questionDTOList && questionDTOList.length) {
			setQuestions(questionDTOList);
		}
	};

	const handleAddQuestion = () => {
		setQuestions([
			...questions,
			{
				// id: 7517,
				videoId: showEditVideo.id,
				title: '',
				description: '',
				score: 0,
				answerExpiredTime: 0,
				appearTime: 0,
				questionChoiceListDTO: [
					{
						// id: 7518,
						description: '',
						isTrue: true,
					},
				],
			},
		]);
	};

	const handleRemoveQuestion = (questionIndex) => {
		const questionDTOList = [...questions];

		questionDTOList.splice(questionIndex, 1);

		setQuestions([...questionDTOList]);
	};

	const handleAddSurvey = () => {
		setSurveys([]);
	};

	const handleRemoveSurvey = (surveyIndex) => {
		const surveyDTOList = [...surveys];

		surveyDTOList.splice(surveyIndex, 1);

		setSurveys([...surveyDTOList]);
	};

	/* return */

	return (
		<Page>
			<Page.Header header="Akademi" title="Tüm Konular">
				<Page.Header.Add show onClick={() => setShowEditTopic(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getAcademyTopics.loading}
				show={
					!api.getAcademyTopics.loading &&
					api.getAcademyTopics.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Konu',
								'Sıra',
								'Kategori',
								'Tarih',
								'Uzunluk',
								'Videolar',
								'',
							]}
						/>
						<Table.Body>
							{api.getAcademyTopics.response?.data?.length &&
								api.getAcademyTopics.response.data.map(
									(item, index) => (
										<>
											<Table.Row
												key={`${index}-${item.id}`}
											>
												<Table.Column>
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.id}
													</span>
												</Table.Column>
												<Table.Column>
													<Item
														image={
															item.url
																? item.url
																: require('../../assets/logo-connection.png')
														}
														title={item.topicName}
														description={
															item.description
														}
														className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
													/>
												</Table.Column>
												<Table.Column>
													{index + 1}
												</Table.Column>
												<Table.Column>
													<div className="truncate whitespace-nowrap text-xs">
														{item.videoCategoryName}
													</div>
												</Table.Column>
												<Table.Column>
													<Time
														dateBegin={moment(
															item.createDate
														).format('DD.MM.YYYY')}
														timeBegin={moment(
															item.createDate
														).format('HH:mm:ss')}
													/>
												</Table.Column>
												<Table.Column>
													<div className="text-base font-medium">
														{item.totalDuration} sn
													</div>
												</Table.Column>
												<Table.Column>
													<div className="text-base font-medium">
														{item.totalVideoNumber}
													</div>
												</Table.Column>
												<Table.Column className="text-right [&_button]:w-20">
													<div className="flex flex-col items-end justify-center gap-2">
														<Button
															size="xs"
															variant="secondary-outline"
															className="inline-flex"
															onClick={() => {
																setShowEditTopic(
																	{
																		id: item.id,
																	}
																);
															}}
														>
															Düzenle
														</Button>
													</div>
												</Table.Column>
											</Table.Row>
											{item.videoSubTopicDTOList &&
												item.videoSubTopicDTOList.map(
													(subItem, subIndex) => (
														<Table.Row
															key={`${index}-${item.id}-${subIndex}-${subItem.id}`}
															className={`${
																item
																	.videoSubTopicDTOList
																	.length ===
																subIndex + 1
																	? 'border-b-[16px]'
																	: ''
															}`}
														>
															<Table.Column>
																<span className="whitespace-nowrap text-xs font-normal text-slate-400">
																	{subItem.id}
																</span>
															</Table.Column>
															<Table.Column>
																<div className="flex w-72 items-center gap-2">
																	<IoChevronForward className="h-3 w-3 opacity-50" />
																	<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
																		{
																			subItem.name
																		}
																	</h6>
																</div>
															</Table.Column>
															<Table.Column>
																{
																	subItem.subTopicOrder
																}
															</Table.Column>
															<Table.Column>
																<div className="truncate whitespace-nowrap text-xs">
																	{
																		item.videoCategoryName
																	}
																</div>
															</Table.Column>
															<Table.Column />
															<Table.Column />
															<Table.Column />
															<Table.Column className="text-right [&_button]:w-20">
																<div className="flex flex-col items-end justify-center gap-2">
																	<Button
																		size="xs"
																		variant="primary-outline"
																		className="inline-flex"
																		onClick={() => {
																			getAcademyVideos(
																				{
																					topicId:
																						item.id,
																				}
																			);
																			setShowVideos(
																				{
																					id: item.id,
																					subId: subItem.id,
																				}
																			);
																		}}
																	>
																		Eğitimler
																	</Button>
																	<Button
																		size="xs"
																		variant="secondary-outline"
																		className="inline-flex"
																		onClick={() => {
																			setShowEditTopic(
																				{
																					id: item.id,
																					subId: subItem.id,
																				}
																			);
																		}}
																	>
																		Düzenle
																	</Button>
																</div>
															</Table.Column>
														</Table.Row>
													)
												)}
										</>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			{/* topic modal */}
			<Modal
				show={Boolean(showEditTopic)}
				loading={
					api.getAcademyCategories.loading ||
					api.getAcademyTopics.loading ||
					api.saveAcademyTopic.loading ||
					api.removeAcademyTopic.loading
				}
				size="lg"
				title={!showEditTopic.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setCurrentImage([]);
					setShowEditTopic(false);
					resetSaveAcademyTopic();
				}}
			>
				<form
					onSubmit={handleSubmitSaveAcademyTopic(saveAcademyTopic)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						{showEditTopic === true && (
							<Select
								name="type"
								label="Tür"
								grid
								options={[
									{ name: 'Konu', value: 'topic' },
									{ name: 'Alt konu', value: 'subTopic' },
								]}
								defaultValue="topic"
								register={registerSaveAcademyTopic}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAcademyTopic}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						)}
						{((showEditTopic === true &&
							watchSaveAcademyTopic('type') === 'topic') ||
							(showEditTopic.id && !showEditTopic.subId)) && (
							<>
								<Select
									name="videoCategoryId"
									label="Kategori"
									grid
									options={[
										...[
											{
												name: 'Lütfen seçiniz.',
												value: '',
											},
										],
										...(api.getAcademyCategories.response
											?.data?.length
											? api.getAcademyCategories.response.data.map(
												(item) => ({
													name: item.categoryName,
													value: item.id,
												})
											)
											: []),
									]}
									defaultValue=""
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ select: '!text-sm' }}
								/>
								<Input
									type="text"
									name="name"
									label="Başlık"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
								<Input
									type="text"
									name="description"
									label="Açıklama"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
								{/* images start */}
								<div className="group">
									<div className="grid grid-cols-3 items-center">
										<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
											Görsel
										</label>
										<div className="col-span-2">
											<div className="mb-4 grid grid-cols-4 gap-4">
												{currentImage &&
												currentImage.length ? (
														currentImage.map(
															(image, index) => (
																<div
																	className="relative inline-block"
																	key={index}
																>
																	<img
																		src={
																			typeof image ===
																		'object'
																				? window.URL.createObjectURL(
																					image
																				)
																				: image
																		}
																		key={index}
																		alt=""
																		className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
																	/>
																	<button
																		type="button"
																		className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																		onClick={() =>
																			removeImage(
																				index
																			)
																		}
																	>
																		<BsFillXCircleFill
																			className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
																		/>
																	</button>
																</div>
															)
														)
													) : (
														<div
															className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
														>
														Görsel Bulunamadı
														</div>
													)}
											</div>
											<div className="relative">
												<input
													type="file"
													accept="image/*"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setCurrentImage([
																...currentImage,
																event.target
																	.files[0],
															]);
														}
													}}
													className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
												/>
												{!currentImage.length && (
													<div
														className={`flex h-9 w-full items-center justify-center whitespace-nowrap
                                                        rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
                                                        transition
                                                        duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
                                                    `}
													>
														Yeni Görsel Ekle
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								{/* images end */}
							</>
						)}

						{((showEditTopic === true &&
							watchSaveAcademyTopic('type') === 'subTopic') ||
							showEditTopic.subId) && (
							<>
								<Select
									name="videoTopicId"
									label="Konu"
									grid
									options={[
										...[
											{
												name: 'Lütfen seçiniz.',
												value: '',
											},
										],
										...(api.getAcademyTopics.response?.data
											?.length
											? api.getAcademyTopics.response.data.map(
												(item) => ({
													name: item.topicName,
													value: item.id,
												})
											)
											: []),
									]}
									defaultValue=""
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ select: '!text-sm' }}
								/>
								<Input
									type="text"
									name="name"
									label="Başlık"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
								<Input
									type="number"
									name="subTopicOrder"
									label="Sıra"
									grid
									placeholder="Lütfen giriniz."
									defaultValue="1"
									register={registerSaveAcademyTopic}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveAcademyTopic}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
							</>
						)}
					</div>

					{showEditTopic.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveAcademyTopic}
							defaultValue={showEditTopic.id}
						/>
					)}
					{showEditTopic.subId && (
						<Input
							type="hidden"
							name="subId"
							register={registerSaveAcademyTopic}
							defaultValue={showEditTopic.subId}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{(showEditTopic.id || showEditTopic.subId) && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeAcademyTopic(
										showEditTopic.subId
											? showEditTopic.subId
											: showEditTopic.id
									);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveAcademyTopic).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			{/* videos modal */}
			<Modal
				show={!!showVideos.id}
				loading={api.getAcademyVideos.loading}
				size="xxl"
				title="Eğitimler"
				onClose={() => {
					setShowVideos(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					<div className="flex justify-end">
						<Page.Header.Add
							show
							onClick={() => setShowEditVideo(true)}
						/>
					</div>

					{api.getAcademyVideos.response?.data?.content?.filter(
						(object) => object.videoSubTopicId === showVideos.subId
					).length ? (
							<Table className="!table-fixed">
								<Table.Head
									data={[
										'Id',
										'Eğitim',
										'Sıra',
										'Tür',
										'Önizleme',
										'Anket',
										'Yarışma',
										'',
									]}
								/>
								<Table.Body>
									{api.getAcademyVideos.response.data.content
										.filter(
											(object) =>
												object.videoSubTopicId ===
											showVideos.subId
										)
										.map((item) => (
											<Table.Row key={item.videoId}>
												<Table.Column>
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.videoId}
													</span>
												</Table.Column>
												<Table.Column>
													<Item
														image={
															item.videoThumbnailUrl
																? item.videoThumbnailUrl
																: require('../../assets/logo-connection.png')
														}
														title={item.title}
														description={
															item.description
																? `${item.description
																	.replace(
																		/<[^>]*>/g,
																		''
																	)
																	.slice(
																		0,
																		50
																	)}...`
																: ''
														}
														className="w-72 [&_img]:h-16 [&_img]:w-16"
													/>
												</Table.Column>
												<Table.Column>
													<div className="text-base font-medium">
														{item.videoOrder}
													</div>
												</Table.Column>
												<Table.Column>
													<Badge variant="default">
														{item.videoContentType &&
														{
															VIDEO: 'Video',
															EMBEDDED_VIDEO:
																'Adres',
															PHOTO: 'Görsel',
														}[
															item
																.videoContentType
														]}
													</Badge>
												</Table.Column>
												<Table.Column>
													{(item.videoUrl ||
													item.embeddedVideoUrl) && (
														<a
															href={
																item.videoUrl
																	? item.videoUrl
																	: item.embeddedVideoUrl
															}
															className="flex gap-2"
															target="_blank"
															rel="noreferrer"
														>
															<BsFillPlayCircleFill className="h-4 w-4 text-green-500" />
															<div className="w-48 truncate whitespace-nowrap text-xs">
																{item.embeddedVideoUrl &&
																item.embeddedVideoUrl}
																{item.videoUrl &&
																item.videoUrl}
															</div>
														</a>
													)}
												</Table.Column>
												<Table.Column>
													{item.surveyDTOList ? (
														<BsCheckCircleFill className="h-4 w-4 text-green-500" />
													) : (
														<BsFillXCircleFill className="h-4 w-4 text-red-500" />
													)}
												</Table.Column>
												<Table.Column>
													{item.questionDTOList ? (
														<BsCheckCircleFill className="h-4 w-4 text-green-500" />
													) : (
														<BsFillXCircleFill className="h-4 w-4 text-red-500" />
													)}
												</Table.Column>
												<Table.Column className="text-right [&_button]:w-20">
													<div className="flex flex-col items-end justify-center gap-2">
														{/* <Button
														size="xs"
														variant="primary-outline"
														className="inline-flex"
														onClick={() => {
															getAcademyVideos({
																topicId:
																	item.id,
															});
															setShowVideos({
																id: item.id,
															});
														}}
													>
														Sonuçlar
													</Button> */}
														<Button
															size="xs"
															variant="secondary-outline"
															className="inline-flex"
															onClick={() => {
																handleEditVideo(
																	item.videoId
																);
															}}
														>
														Düzenle
														</Button>
													</div>
												</Table.Column>
											</Table.Row>
										))}
								</Table.Body>
							</Table>
						) : (
							<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
							Eğitim bulunamadı
							</div>
						)}
				</div>
			</Modal>

			{/* edit video modal */}
			<Modal
				show={Boolean(showEditVideo)}
				loading={
					api.getAcademyVideos.loading ||
					api.saveAcademyVideo.loading ||
					api.removeAcademyVideo.loading
				}
				size="lg"
				title={!showEditVideo.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEditVideo(false);
					setQuestions([]);
					setVideoFile();
					setVideoContent();
					resetSaveAcademyVideo();
				}}
			>
				<form
					onSubmit={handleSubmitSaveAcademyVideo(saveAcademyVideo)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="İçerik">
							<Input
								type="text"
								name="title"
								label="Başlık"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAcademyVideo}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAcademyVideo}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<TextArea
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAcademyVideo}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAcademyVideo}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="number"
								name="videoOrder"
								label="Sıra"
								grid
								placeholder="Lütfen giriniz."
								defaultValue="1"
								register={registerSaveAcademyVideo}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAcademyVideo}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>
						<Modal.Section title="Video">
							<Select
								name="videoContentType"
								label="Tür"
								grid
								options={[
									{
										name: 'Adres',
										value: 'EMBEDDED_VIDEO',
									},
									{
										name: 'Video',
										value: 'VIDEO',
									},
								]}
								defaultValue="EMBEDDED_VIDEO"
								register={registerSaveAcademyVideo}
								errors={errorsSaveAcademyVideo}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							{(!watchSaveAcademyVideo('videoContentType') ||
								watchSaveAcademyVideo('videoContentType') ===
									'EMBEDDED_VIDEO') && (
								<Input
									type="text"
									name="embeddedVideoUrl"
									label="Adres"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveAcademyVideo}
									errors={errorsSaveAcademyVideo}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
							)}
							{/* video file start */}
							{/* eslint-disable */}
							{(!watchSaveAcademyVideo('videoContentType') ||
								watchSaveAcademyVideo('videoContentType') ===
									'VIDEO') && (
								<div className="group">
									<div className="grid grid-cols-3 items-center">
										<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
											Video Dosyası
										</label>
										<div className="col-span-2">
											<div className="mb-4">
												{!videoFile && videoContent && (
													<div className="relative">
														<video
															width="750"
															height={400}
															controls
															className="w-full"
														>
															<source
																src={
																	videoContent
																}
															/>
														</video>
														<button
															type="button"
															className="absolute top-0 right-0 translate-x-2 -translate-y-2"
															onClick={() => {
																setVideoContent(
																	null
																);
																setVideoFile(
																	null
																);
															}}
														>
															<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)}
												{!videoContent && videoFile && (
													<div className="relative inline-block">
														<video
															width="750"
															height={400}
															controls
															className="w-full"
														>
															<source
																src={window.URL.createObjectURL(
																	videoFile
																)}
															/>
														</video>
														<button
															type="button"
															className="absolute top-0 right-0 translate-x-2 -translate-y-2"
															onClick={() =>
																setVideoFile(
																	null
																)
															}
														>
															<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)}
											</div>
											<div className="relative">
												<div className="col-span-4 mb-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
													Video Bulunamadı
												</div>
												<input
													type="file"
													accept="video/*"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setVideoFile(
																event.target
																	.files[0]
															);
														}
													}}
													className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
												/>
												{!videoFile && (
													<div
														className={`flex h-9 w-full items-center justify-center whitespace-nowrap
															rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
															transition
															duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
														`}
													>
														Yeni Video Ekle
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							{/* eslint-enable */}
							{/* video file end */}
						</Modal.Section>
						<Modal.Section title="Sorular">
							{questions.length ? (
								questions.map((question, questionIndex) => (
									<div
										className="flex flex-col gap-2 rounded-md p-4 bg-slate-50"
										key={questionIndex}
									>
										<div className="mb-4 flex flex-row items-center justify-between">
											<h6 className="text-sm font-medium">
												SORU {questionIndex + 1}
											</h6>
											<button
												type="button"
												onClick={() =>
													handleRemoveQuestion(
														questionIndex
													)
												}
											>
												<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
											</button>
										</div>

										{/* {questionIndex} */}
										{/* {question.id} */}
										{/* {question.videoId} */}

										{/* {question.title} */}
										{/* {question.description} */}
										{/* {question.score} */}
										{/* {question.answerExpiredTime} */}
										{/* {question.appearTime} */}

										{/* {question.questionChoiceListDTO?.map(
											(choice, choiceIndex) => (
												<div>
													{choiceIndex}
													{choice.description}
													{choice.id}
													{choice.isTrue}
												</div>
											)
										)} */}

										{question.id && (
											<Input
												type="hidden"
												name={`saveQuestionRequestList[${questionIndex}][id]`}
												register={
													registerSaveAcademyVideo
												}
												defaultValue={question.id}
											/>
										)}
										<Input
											type="text"
											name={`saveQuestionRequestList[${questionIndex}][title]`}
											label="Başlık"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveAcademyVideo}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											defaultValue={question.title}
											errors={errorsSaveAcademyVideo}
											size="lg"
											className={{
												input: '!text-sm bg-white',
											}}
										/>
										<Input
											type="text"
											name={`saveQuestionRequestList[${questionIndex}][description]`}
											label="Açıklama"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveAcademyVideo}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											defaultValue={question.description}
											errors={errorsSaveAcademyVideo}
											size="lg"
											className={{
												input: '!text-sm bg-white',
											}}
										/>
										<Input
											type="number"
											name={`saveQuestionRequestList[${questionIndex}][score]`}
											label="Puan"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveAcademyVideo}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											defaultValue={question.score}
											errors={errorsSaveAcademyVideo}
											size="lg"
											className={{
												input: '!text-sm bg-white',
											}}
										/>
										<Input
											type="number"
											name={`saveQuestionRequestList[${questionIndex}][answerExpiredTime]`}
											label="Geçerlilik Süresi (sn)"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveAcademyVideo}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											defaultValue={
												question.answerExpiredTime
											}
											errors={errorsSaveAcademyVideo}
											size="lg"
											className={{
												input: '!text-sm bg-white',
											}}
										/>
										<Input
											type="number"
											name={`saveQuestionRequestList[${questionIndex}][appearTime]`}
											label="Kalma Süresi (sn)"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveAcademyVideo}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											defaultValue={question.appearTime}
											errors={errorsSaveAcademyVideo}
											size="lg"
											className={{
												input: '!text-sm bg-white',
											}}
										/>
									</div>
								))
							) : (
								<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
									Soru bulunamadı
								</div>
							)}
							<button
								className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
								type="button"
								onClick={() => {
									handleAddQuestion();
								}}
							>
								Yeni Soru Ekle
							</button>
						</Modal.Section>

						<div className="hidden">
							<Modal.Section title="Anketler">
								{surveys.length ? (
									surveys.map((survey, surveyIndex) => (
										<div
											className="flex flex-col gap-2 rounded-md p-4 bg-slate-50"
											key={surveyIndex}
										>
											<div className="mb-4 flex flex-row items-center justify-between">
												<h6 className="text-sm font-medium">
													ANKET {surveyIndex + 1}
												</h6>
												<button
													type="button"
													onClick={() =>
														handleRemoveSurvey(
															surveyIndex
														)
													}
												>
													<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
												</button>
											</div>

											<Input
												type="text"
												name={`createSurveyRequests[${surveyIndex}][title]`}
												label="Başlık"
												grid
												placeholder="Lütfen giriniz."
												register={
													registerSaveAcademyVideo
												}
												validation={{
													required:
														'Bu alan zorunludur.',
												}}
												errors={errorsSaveAcademyVideo}
												size="lg"
												className={{
													input: '!text-sm bg-white',
												}}
											/>
										</div>
									))
								) : (
									<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
										Anket bulunamadı
									</div>
								)}
								<button
									className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
									type="button"
									onClick={() => {
										handleAddSurvey();
									}}
								>
									Yeni Anket Ekle
								</button>
							</Modal.Section>
						</div>
					</div>

					{showVideos.subId && (
						<Input
							type="hidden"
							name="videoSubTopicId"
							register={registerSaveAcademyVideo}
							defaultValue={showVideos.subId}
						/>
					)}

					{showEditVideo.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveAcademyVideo}
							defaultValue={showEditVideo.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEditVideo.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeAcademyVideo(showEditVideo.id);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveAcademyVideo).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default AcademyTopics;
