import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
// import { IoChevronForward } from 'react-icons/io5';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Table,
	TextArea,
	Time,
} from '../../components';
import { useAxios } from '../../hooks';

const Contents = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [videoFile, setVideoFile] = React.useState();
	const [videoContent, setVideoContent] = React.useState();
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveContent,
		// watch: watchSaveContent,
		setValue: setValueSaveContent,
		handleSubmit: handleSubmitSaveContent,
		reset: resetSaveContent,
		formState: { errors: errorsSaveContent },
	} = useForm();

	const api = {
		getContents: useAxios(),
		saveContent: useAxios(),
		removeContent: useAxios(),
		getContentSubCategories: useAxios(),
	};

	/* getContents */

	const getContents = () => {
		api.getContents.fetch({
			method: 'GET',
			url: '/magazine/admin/articleList?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		if (api.getContents.response) {
			// console.log(api.getContents.response);
		}
	}, [api.getContents.response]);

	React.useEffect(() => {
		if (api.getContents.error) {
			toast.error(api.getContents.error.message);
		}
	}, [api.getContents.error]);

	React.useEffect(() => {
		getContents();
	}, []);

	/* saveContent */

	// embededVideo
	// icon
	// {
	// 	"id":65,
	// 	++ "name": "sinatest3name",
	// 	++ "description": "sinatest2description",
	// 	++ "topicSubCategoryId": 4,
	// 	++ "dateBegin": "2023-11-14T15:00:00Z",
	// 	++ "dateEnd": "2024-11-15T05:00:00Z",
	// 	++ "active": 1,
	// 	++ "articleType": "MAGAZINE"  - TV
	// 	"consents":[
	// 		"https://cdn.724loyal.com/files/tenant1/202401/ba9e7947-9b2c-4504-92a9-16bd7636a337.jpg"
	// 	]
	// }

	const saveContent = async (data) => {
		const formData = new FormData();

		console.log('data', data);

		formData.append(
			'saveArticleDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				topicSubCategoryId: parseInt(data.topicSubCategoryId, 10),
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
				...(videoContent
					? { videoArticleContent: videoContent }
					: null),
			})
		);

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}
		if (videoFile) {
			formData.append('videoFile', videoFile);
		}
		/* else {
			if (!videoContent) {
				formData.delete('videoFile');
			}
		} */

		if (data.embededVideo === '') {
			formData.delete('saveArticleDTO.embededVideo');
		}

		console.log(formData.values());

		api.saveContent.fetch({
			method: 'POST',
			url: '/magazine/admin/saveArticle',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		/*
		const body = {
			saveArticleDTO: {
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				topicSubCategoryId: parseInt(data.topicSubCategoryId, 10),
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? {
							consents: [currentImage[0]],
					  }
					: null),
			},
			...(currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
				? {
						icon: currentImage[0],
				  }
				: null),
		};

		if (data.videoFile) {
			body.videoFile = data.videoFile;
		}

		if (data.embededVideo === '') {
			delete body.saveArticleDTO.embededVideo;
		}

		console.log(body);

		api.saveContent.fetch({
			method: 'POST',
			url: '/magazine/admin/saveArticle',
			data: body,
			// headers: {
			// 	'Content-Type': 'multipart/form-data',
			// },
		});
		*/
	};

	React.useEffect(() => {
		if (api.saveContent.response) {
			resetSaveContent();
			setCurrentImage([]);
			setVideoFile(null);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getContents();
		}
	}, [api.saveContent.response]);

	React.useEffect(() => {
		if (api.saveContent.error) {
			toast.error(api.saveContent.error.message);
		}
	}, [api.saveContent.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	/*

		++ name "How This Woman Mastered Jump Rope"
		++ description "For most of us, jumping rope may seem like a simple childhood pastime,

		++ articleType "TV"
		? topicCategoryName "denemeTV" topicCategoryId 207
		++ topicSubCategoryName "Otomotiv" topicSubCategoryId 208

		++ embededVideo "https://www.dailymotion.com/video/x89hb1f"

		++ dateBegin "2021-01-01T17:00:00+03:00"
		++ dateEnd "2025-12-30T16:59:18+03:00"
		++ active true

		++ icon
		? videoFile

			"articleContentDTOList": [
				{
					"id": 10772,
					"articleId": 10772,
					"baseAddress": "https://cdn.724loyal.com/files/tenant1/202401/68804171-c0cd-4473-a2a6-e6c14e450d05.jpg"
				}
			],

	*/

	React.useEffect(() => {
		if (
			showEdit.id &&
			api.getContents.response &&
			api.getContentSubCategories.response
		) {
			const { id } = showEdit;
			const item =
				api.getContents.response.data.content[
					api.getContents.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			console.log('item: ', item.videoArticleContent);
			setVideoContent(item.videoArticleContent);
			const values = [
				{ name: item.name },
				{ description: item.description },
				{ articleType: item.articleType },
				{ embededVideo: item.embededVideo },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				{ topicSubCategoryId: item.topicSubCategoryId },
			];

			values.forEach((value) => {
				setValueSaveContent(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (
				item.articleContentDTOList &&
				Array.isArray(item.articleContentDTOList)
			) {
				const images = [];
				item.articleContentDTOList.forEach((image) => {
					images.push(image.baseAddress);
				});
				setCurrentImage(images);
			}
		}
	}, [
		showEdit.id &&
			api.getContents.response &&
			api.getContentSubCategories.response,
	]);

	/* removeContent */

	const removeContent = (id) => {
		api.removeContent.fetch({
			method: 'POST',
			url: `/magazine/admin/article/delete/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeContent.response) {
			setCurrentImage([]);
			setShowEdit(false);
			toast.success('Silindi.');
			getContents();
		}
	}, [api.removeContent.response]);

	React.useEffect(() => {
		if (api.removeContent.error) {
			toast.error(api.removeContent.error.message);
		}
	}, [api.removeContent.error]);

	/* getContentSubCategories */

	const getContentSubCategories = () => {
		api.getContentSubCategories.fetch({
			method: 'GET',
			url: '/magazine/admin/topicSubCategory',
		});
	};

	React.useEffect(() => {
		if (api.getContentSubCategories.response) {
			// console.log(api.getContentSubCategories.response);
		}
	}, [api.getContentSubCategories.response]);

	React.useEffect(() => {
		if (api.getContentSubCategories.error) {
			toast.error(api.getContentSubCategories.error.message);
		}
	}, [api.getContentSubCategories.error]);

	React.useEffect(() => {
		getContentSubCategories();
	}, []);

	/* return */

	return (
		<Page>
			<Page.Header header="İçerikler" title="Tüm İçerikler">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getContents.loading}
				show={!api.getContents.loading && api.getContents.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'İçerik',

								'Tür',
								'Kategori',

								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{api.getContents.response?.data?.content?.length &&
								api.getContents.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.articleContentDTOList &&
														item
															.articleContentDTOList[0] &&
														item
															.articleContentDTOList[0]
															.baseAddress
															? item
																.articleContentDTOList[0]
																.baseAddress
															: require('../../assets/logo-connection.png')
													}
													title={item.name}
													description={`${item.description
														.replace(/<[^>]*>/g, '')
														.slice(0, 50)}...`}
													className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
													{item.articleType ===
														'TV' && 'TV'}
													{item.articleType ===
														'MAGAZINE' && 'Magazin'}
												</div>
											</Table.Column>
											<Table.Column>
												<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
													{item.topicSubCategoryName}
													{/* {item.topicCategoryName}
													{item.topicSubCategoryName && (
														<>
															<IoChevronForward className="h-3 w-3 opacity-50" />
															{
																item.topicSubCategoryName
															}
														</>
													)} */}
												</div>
											</Table.Column>

											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												{item.active ? (
													<Badge variant="success">
														<div
															className="aspect-square w-1.5 animate-pulse
													rounded-full bg-green-500"
														/>
														Aktif
													</Badge>
												) : (
													<Badge variant="danger">
														<div className="aspect-square w-1.5 rounded-full bg-red-500" />
														Pasif
													</Badge>
												)}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getContents.loading ||
					api.saveContent.loading ||
					api.getContentSubCategories.loading ||
					api.removeContent.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentImage([]);
					setVideoFile();
					resetSaveContent();
				}}
			>
				<form
					onSubmit={handleSubmitSaveContent(saveContent)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="İçerik">
							<Input
								type="text"
								name="name"
								label="İsim"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveContent}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveContent}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<TextArea
								name="description"
								label="İçerik"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveContent}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveContent}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateBegin"
								label="Başlangıç Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now()).format(
									'YYYY-MM-DD HH:mm'
								)}
								register={registerSaveContent}
								errors={errorsSaveContent}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateEnd"
								label="Bitiş Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now())
									.add(1, 'years')
									.format('YYYY-MM-DD HH:mm')}
								register={registerSaveContent}
								errors={errorsSaveContent}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="active"
								label="Durum"
								grid
								options={[
									{
										name: 'Aktif',
										value: true,
									},
									{
										name: 'Pasif',
										value: false,
									},
								]}
								defaultValue
								register={registerSaveContent}
								errors={errorsSaveContent}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>
						<Modal.Section title="Tür ve Kategori">
							<Select
								name="articleType"
								label="Tür"
								grid
								options={[
									{ name: 'Lütfen seçiniz.', value: '' },
									{ name: 'TV', value: 'TV' },
									{ name: 'Magazin', value: 'MAGAZINE' },
								]}
								defaultValue="MAGAZINE"
								register={registerSaveContent}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveContent}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Select
								name="topicSubCategoryId"
								label="Kategori"
								grid
								options={[
									...[{ name: 'Lütfen seçiniz.', value: '' }],
									...(api.getContentSubCategories.response
										?.data?.content?.length
										? api.getContentSubCategories.response.data.content.map(
											(item) => ({
												name: item.name,
												value: item.id,
											})
										)
										: []),
								]}
								defaultValue=""
								register={registerSaveContent}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveContent}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>
						<Modal.Section title="Video">
							<Input
								type="text"
								name="embededVideo"
								label="Video Adresi"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveContent}
								// validation={{
								// 	required: 'Bu alan zorunludur.',
								// }}
								errors={errorsSaveContent}
								size="lg"
								className={{ input: '!text-sm' }}
							/>

							{/* video file start */}
							{/* eslint-disable */}
							<div className="group">
								<div className="grid grid-cols-3 items-center">
									<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
										Video Dosyası
									</label>
									<div className="col-span-2">
										<div className="mb-4">
											{!videoFile && videoContent && (
												<div className="relative">
													<video
														width="750"
														height={400}
														controls
														className="w-full"
													>
														<source
															src={videoContent}
														/>
													</video>
													<button
														type="button"
														className="absolute top-0 right-0 translate-x-2 -translate-y-2"
														onClick={() => {
															setVideoContent(
																null
															);
															setVideoFile(null);
														}}
													>
														<BsFillXCircleFill
															className="translate h-5 w-5
											rounded-full border-2 bg-white border-white text-red-500"
														/>
													</button>
												</div>
											)}
											{videoFile ? (
												<div className="relative inline-block">
													<video
														width="750"
														height={400}
														controls
														className="w-full"
													>
														<source
															src={window.URL.createObjectURL(
																videoFile
															)}
														/>
													</video>
													<button
														type="button"
														className="absolute top-0 right-0 translate-x-2 -translate-y-2"
														onClick={() =>
															setVideoFile(null)
														}
													>
														<BsFillXCircleFill
															className="translate h-5 w-5
													rounded-full border-2 bg-white border-white text-red-500"
														/>
													</button>
												</div>
											) : (
												<div
													className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
												>
													Video Bulunamadı
												</div>
											)}
										</div>
										<div className="relative">
											<input
												type="file"
												accept="video/*"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setVideoFile(
															event.target
																.files[0]
														);
													}
												}}
												className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
											/>
											{!videoFile && (
												<div
													className={`flex h-9 w-full items-center justify-center whitespace-nowrap
													rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
													transition
													duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
												>
													Yeni Video Ekle
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* eslint-enable */}
							{/* video file end */}
						</Modal.Section>

						<Modal.Section title="Görsel">
							{/* images start */}
							<div className="group">
								<div className="grid grid-cols-3 items-center">
									<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
										Görsel
									</label>
									<div className="col-span-2">
										<div className="mb-4 grid grid-cols-4 gap-4">
											{currentImage &&
											currentImage.length ? (
													currentImage.map(
														(image, index) => (
															<div
																className="relative inline-block"
																key={index}
															>
																<img
																	src={
																		typeof image ===
																	'object'
																			? window.URL.createObjectURL(
																				image
																			)
																			: image
																	}
																	key={index}
																	alt=""
																	className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
																/>
																<button
																	type="button"
																	className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																	onClick={() =>
																		removeImage(
																			index
																		)
																	}
																>
																	<BsFillXCircleFill
																		className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
																	/>
																</button>
															</div>
														)
													)
												) : (
													<div
														className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
													>
													Görsel Bulunamadı
													</div>
												)}
										</div>
										<div className="relative">
											<input
												type="file"
												accept="image/*"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setCurrentImage([
															...currentImage,
															event.target
																.files[0],
														]);
													}
												}}
												className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
											/>
											{!currentImage.length && (
												<div
													className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition
											duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
												>
													Yeni Görsel Ekle
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* images end */}
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveContent}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeContent(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveContent).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Contents;
