import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Modal, Page, Table, Time } from '../../components';
import { useAxios } from '../../hooks';

const PageDescriptions = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const editorRef = React.useRef(null);
	const [editorValue, setEditorValue] = React.useState();

	const {
		register: registerSavePageDescription,
		setValue: setValueSavePageDescription,
		handleSubmit: handleSubmitSavePageDescription,
		reset: resetSavePageDescription,
		formState: { errors: errorsSavePageDescription },
	} = useForm();

	const api = {
		getPageDescriptions: useAxios(),
		savePageDescription: useAxios(),
		removePageDescription: useAxios(),
	};

	/* getPageDescriptions */

	const getPageDescriptions = () => {
		api.getPageDescriptions.fetch({
			method: 'GET',
			url: '/profile/admin/pageDescriptionAll',
		});
	};

	React.useEffect(() => {
		if (api.getPageDescriptions.response) {
			// console.log(api.getPageDescriptions.response);
		}
	}, [api.getPageDescriptions.response]);

	React.useEffect(() => {
		if (api.getPageDescriptions.error) {
			toast.error(api.getPageDescriptions.error.message);
		}
	}, [api.getPageDescriptions.error]);

	React.useEffect(() => {
		getPageDescriptions();
	}, []);

	/* savePageDescription */

	const savePageDescription = async (data) => {
		const body = {
			...data,
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			description: editorRef.current.getContent(),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
		};

		api.savePageDescription.fetch({
			method: 'POST',
			url: '/profile/pageDescription/save',
			data: body,
		});
	};

	React.useEffect(() => {
		if (api.savePageDescription.response) {
			resetSavePageDescription();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getPageDescriptions();
		}
	}, [api.savePageDescription.response]);

	React.useEffect(() => {
		if (api.savePageDescription.error) {
			toast.error(api.savePageDescription.error.message);
		}
	}, [api.savePageDescription.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getPageDescriptions.response) {
			const { id } = showEdit;
			const item =
				api.getPageDescriptions.response.data[
					api.getPageDescriptions.response.data.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ title: item.title },
				{ type: item.type },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
			];

			values.forEach((value) => {
				setValueSavePageDescription(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			setEditorValue(item.description);
		}
	}, [showEdit.id && api.getPageDescriptions.response]);

	/* removePageDescription */

	const removePageDescription = (id) => {
		api.removePageDescription.fetch({
			method: 'POST',
			url: '/profile/pageDescription/delete/',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removePageDescription.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getPageDescriptions();
		}
	}, [api.removePageDescription.response]);

	React.useEffect(() => {
		if (api.removePageDescription.error) {
			toast.error(api.removePageDescription.error.message);
		}
	}, [api.removePageDescription.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Metinler" title="Tüm Sayfa Açıklamaları">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getPageDescriptions.loading}
				show={
					!api.getPageDescriptions.loading &&
					api.getPageDescriptions.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Başlık', 'Bağlantı', 'Tarih', '']}
						/>
						<Table.Body>
							{api.getPageDescriptions.response?.data?.length &&
								api.getPageDescriptions.response.data.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<div className="grid gap-1">
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.title}
													</h6>
												</div>
											</Table.Column>
											<Table.Column>
												<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
													{item.type}
												</div>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getPageDescriptions.loading ||
					api.savePageDescription.loading ||
					api.removePageDescription.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setEditorValue();
					resetSavePageDescription();
				}}
			>
				<form
					onSubmit={handleSubmitSavePageDescription(
						savePageDescription
					)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Input
							type="text"
							name="title"
							label="Başlık"
							grid
							placeholder="Lütfen giriniz."
							register={registerSavePageDescription}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSavePageDescription}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="type"
							label="Bağlantı"
							grid
							placeholder="Lütfen giriniz."
							register={registerSavePageDescription}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSavePageDescription}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Metin
								</label>
								<div className="col-span-2">
									<Editor
										apiKey="w6m1gkj9ggeablptn3x0iq29ndl147gv2bcervbedhxksafy"
										initialValue={editorValue}
										onInit={(evt, editor) =>
											(editorRef.current = editor)
										}
										init={{
											height: 240,
											menubar: false,
											toolbar: 'blocks | bold italic',
											content_style:
												'body { font-size:14px }',
										}}
									/>
								</div>
							</div>
						</div>

						<Input
							type="datetime-local"
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now()).format(
								'YYYY-MM-DD HH:mm'
							)}
							register={registerSavePageDescription}
							errors={errorsSavePageDescription}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now())
								.add(1, 'years')
								.format('YYYY-MM-DD HH:mm')}
							register={registerSavePageDescription}
							errors={errorsSavePageDescription}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSavePageDescription}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removePageDescription(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSavePageDescription).length >
								0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default PageDescriptions;
