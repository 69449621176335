import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { Context, Layout } from './components';
import {
	AcademyCategories,
	AcademyTopics,
	Announcements,
	Brands,
	ContentCategories,
	Contents,
	Dashboard,
	EmployeePhotos,
	Employees,
	ForumBadWords,
	Forums,
	GamePuzzleMatch,
	GameScoreHunt,
	GameLuckyWhell,
	NotFound,
	Orders,
	PageDescriptions,
	ProductCategories,
	Products,
	ResetPassword,
	Scores,
	SignIn,
	SignUp,
	StaticPageCategories,
	StaticPages,
	Surveys,
	GameWordPuzzle,
} from './pages';

import './App.css';

const App = () => {
	const [state, setState] = React.useState({});
	const value = React.useMemo(() => [state, setState], [state]);

	if (Object.entries(state).length === 0) {
		const signed = JSON.parse(localStorage.getItem('signed'));
		if (
			signed?.time &&
			signed?.data?.expireSecond &&
			signed?.data?.expireSecond >= (Date.now() - signed.time) / 1000 + 60
		) {
			setState({
				signed,
				state: {},
			});
		} else {
			localStorage.removeItem('signed');
			localStorage.removeItem('tenant');
		}
	}

	return (
		<Context.Provider value={value}>
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<NotFound />} />
					<Route path="/uye-ol" element={<SignUp />} />
					<Route path="/giris-yap" element={<SignIn />} />
					<Route
						path="/parolami-unuttum"
						element={<ResetPassword />}
					/>
					<Route path="/" element={<Layout.Private />}>
						{/* anasayfa */}
						<Route index element={<Dashboard />} />

						{/* puanli urunler */}
						<Route path="puanli-urunler" element={<Products />} />
						<Route
							path="puanli-urun-kategorileri"
							element={<ProductCategories />}
						/>

						{/* siparisler */}
						<Route path="siparisler" element={<Orders />} />

						{/* uye islemleri */}
						<Route path="uyeler" element={<Employees />} />
						<Route
							path="uye-fotograf-onaylari"
							element={<EmployeePhotos />}
						/>

						{/* puan islemleri */}
						<Route path="puanlar" element={<Scores />} />

						{/* anketler */}
						<Route path="duyurular" element={<Announcements />} />

						{/* anketler */}
						<Route path="anketler" element={<Surveys />} />

						{/* forum */}
						<Route path="forumlar" element={<Forums />} />
						<Route
							path="forum-filtreli-kelimeler"
							element={<ForumBadWords />}
						/>

						{/* markalar */}
						<Route path="markalar" element={<Brands />} />

						{/* icerikler */}
						<Route path="icerikler" element={<Contents />} />
						<Route
							path="icerik-kategorileri"
							element={<ContentCategories />}
						/>

						{/* akademi */}
						<Route
							path="egitim-konulari"
							element={<AcademyTopics />}
						/>
						<Route
							path="egitim-kategorileri"
							element={<AcademyCategories />}
						/>

						{/* metinler */}
						<Route
							path="sayfa-aciklamalari"
							element={<PageDescriptions />}
						/>
						<Route
							path="statik-sayfalar"
							element={<StaticPages />}
						/>
						<Route
							path="statik-sayfa-kategorileri"
							element={<StaticPageCategories />}
						/>

						{/* oyunlar */}
						<Route path="esini-bul" element={<GamePuzzleMatch />} />
						<Route path="puan-avi" element={<GameScoreHunt />} />
						<Route
							path="kelime-bulmaca"
							element={<GameWordPuzzle />}
						/>
						<Route path="sans-carki" element={<GameLuckyWhell />} />
					</Route>
				</Routes>
			</BrowserRouter>

			<Toaster
				position="top-center"
				toastOptions={{
					success: {
						className: 'bg-green-500 text-white text-sm rounded-lg',
						icon: false,
					},
					error: {
						className:
							'bg-red-500 text-white text-sm rounded-lg w-auto max-w-full cursor-pointer',
						icon: false,
					},
				}}
			/>

			{process.env.NODE_ENV === 'development' && (
				<div className="text-white bg-red-500 fixed bottom-2 right-2 flex h-8 w-8 items-center justify-center rounded-md text-sm">
					<div className="hidden max-sm:block">N</div>
					<div className="hidden sm:max-md:block">sm</div>
					<div className="hidden md:max-lg:block">md</div>
					<div className="hidden lg:max-xl:block">lg</div>
					<div className="hidden xl:max-2xl:block">xl</div>
					<div className="hidden 2xl:block">2xl</div>
				</div>
			)}
		</Context.Provider>
	);
};

export default App;
