import React from 'react';
import { Navigate } from 'react-router-dom';

import Context from '../../Context';

const Public = ({ children }) => {
	const [context] = React.useContext(Context);
	return !context?.signed?.data ? (
		<main className="grid min-h-screen w-screen overflow-y-auto lg:grid-cols-2">
			<div className="flex flex-col bg-white">
				<div className="flex grow items-center justify-center p-6">
					{children}
				</div>

				<div className="grid grid-cols-1 items-center gap-8 p-12 lg:grid-cols-2">
					<div className="flex items-center gap-4 max-lg:order-1 max-lg:justify-center">
						<img
							src={require('../../../assets/logo-jarvis.png')}
							alt=""
							className="h-10 w-auto"
						/>
						<div className="flex flex-col gap-1">
							<h6 className="text-sm font-normal text-slate-700">
								<a
									href="https://www.jarvis.com.tr/"
									target="_blank"
									rel="noreferrer"
								>
									Jarvis Yazılım A.Ş.
								</a>
							</h6>
							<p className="text-xs text-slate-400">
								© {new Date().getFullYear()} Tüm hakları
								saklıdır.
							</p>
						</div>
					</div>

					<ul className="flex flex-wrap justify-center gap-x-4 gap-y-2 text-sm lg:justify-end">
						{[
							{
								name: 'Çerez Politikası',
								url: '#',
							},
							{
								name: 'KVKK Metni',
								url: '#',
							},
							{
								name: 'Kullanım Koşulları',
								url: '#',
							},
						].map((item, index) => (
							<li key={index}>
								<a
									href={item.url}
									target="_blank"
									className="text-blue-700 hover:underline"
									rel="noreferrer"
								>
									{item.name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>

			<div className="flex items-center justify-center p-6 bg-blue-900 max-lg:hidden">
				<div className="w-3/4 text-center">
					{/* TODO: login banner */}
					<img
						src="http://172.104.244.253/assets/img/slider-01.png"
						alt=""
						className="mx-auto mb-12 w-1/4"
					/>
					<h2 className="mb-4 text-4xl font-bold !leading-tight text-white">
						Sadakat Programı Çözüm Ortağınız.
					</h2>
					<p className="font-light leading-loose opacity-50 text-white">
						Kanal, müşteri ve çalışanlarınıza özel yeni nesil
						sadakat platformu, tasarım, uygulama ve yönetim
						hizmetleri.
					</p>
				</div>
			</div>
		</main>
	) : (
		<Navigate to="/" replace />
	);
};

export default Public;
